<template>
	<div>
		<vx-card class="mb-base">
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<vs-input class="w-full" label="Domain" v-model="domainSearch['name']" @keyup.enter="search()" />
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<vs-input class="w-full" label="Domain ID" v-model="domainSearch['id']"  @keyup.enter="search()" />
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButtonDomainSearch" id="button-with-loading-domain-search"  class="mb-2 mr-4" @click="search()">Search</vs-button>
					<vs-button class="mb-2" color="danger" type="border" @click="resetDetails()">Reset</vs-button>
				</div>
			</div>
		</vx-card>

		<!-- No Results Alert -->
		<div v-if="searchFlags['searched'] && searchFlags['noResults']" class="vx-col w-full mb-base">
			<vs-alert active="true" icon="new_releases">No domains found for the above search criteria, please change the search criteria & try again.</vs-alert>
		</div>

		<!-- Domain Lifecycle Details -->
		<template v-if="searchFlags['searched'] && !searchFlags['noResults']">
			<vx-card id="domain-loader" class="mb-base vs-con-loading__container" v-for="(item, index) in domainDetails['lifecycle'].slice().reverse()" :key="index">
				<div slot="no-body">
					<div class="vx-card__header">
						<div class="vx-card__title">
							<h4>{{ `${domainLifecycleNumbers(index)}Domain details - ${domainDetails['name']}` }}</h4>
						</div>
						<div v-if="userRole == 'admin'" class="inline-block">
							<vs-button v-if="showActionButton(index, item['whitelisted'], 'whitelisted') == 'whitelist'" class="mr-4" color="dark" type="border" size="small" @click="whitelistConfirm({domain: domainDetails['name'], domain_id: item['domain_id']}, item['whitelisted'])">Whitelist</vs-button>
							<vs-button v-if="showActionButton(index, item['whitelisted'], 'whitelisted') == 'de-whitelist'" class="mr-4" color="dark" type="border" size="small" @click="whitelistConfirm({domain: domainDetails['name'], domain_id: item['domain_id']}, item['whitelisted'])">De-Whitelist</vs-button>
							<vs-button v-if="showActionButton(index, item['locked'], 'locked') == 'lock'" class="mr-4" color="danger" type="border" size="small" @click="registryLock(item['domain_id'], 'lock')">Lock</vs-button>
							<vs-button v-if="showActionButton(index, item['locked'], 'locked') == 'unlock'" class="mr-4" color="danger" type="border" size="small" @click="registryLock(item['domain_id'], 'unlock')">Unlock</vs-button>
							<vs-button v-if="showActionButton(index, item['status'], 'status') == 'takedown'" color="primary" type="border" size="small" @click="takedown(item['domain_id'])">Takedown</vs-button>
							<vs-button v-if="showActionButton(index, item['status'], 'status') == 'unsuspend'" color="primary" type="border" size="small" @click="unsuspend(item['domain_id'])">Unsuspend</vs-button>
						</div>
					</div>
					<div class="vx-row p-6">
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain ID" :value="item['domain_id']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Creation Date" :value="item['transaction_date']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Period" :value="formatYears(item['period'])" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrar Name" :value="item['registrar_organization']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Name" :value="item['registrant_name']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<label for="" class="vs-input--label">Registrant Email <a class="ml-1" :href="`/search?registrant_email=${encodeURIComponent(item['registrant_email'])}`" target="_blank"><feather-icon icon="LinkIcon" svgClasses="h-3 w-3 cursor-pointer"/></a></label>
									<vx-input-group class="w-full">
										<template slot="append">
											<div class="append-text btn-addon">
											<vs-button class="h-full" color="rgb(89, 89, 89)" type="border" icon-pack="feather" icon="icon-eye" @click="viewDecryptedValue(item['registrant_email'])"></vs-button>
											</div>
										</template>

										<vs-input class="w-full label-input" :value="item['registrant_email']" disabled />
									</vx-input-group>
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Country" :value="item['registrant_country']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Phone" :value="item['registrant_phone_voice']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain Status" :value="formatStatus(item['status'])" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<label class="vs-input--label">Nameservers</label>
									<vs-textarea class="w-full label-input" :value="item['nameservers']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain Locked" :value="formatFlag(item['locked'])" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain Whitelisted" :value="formatFlag(item['whitelisted'])" disabled />
								</div>
							</div>
						</div>
					</div>
				</div>

			</vx-card>

			<!-- Email Decryption -->
            <vs-prompt id="password-prompt" vs-title="View Decrypted Value" @vs-cancel="closePasswordPrompt" @vs-close="closePasswordPrompt" :vs-buttons-hidden="true" :vs-active.sync="password['prompt']">
                <div>
                    <vs-input v-model="password['input']" class="w-full" placeholder="Enter password"/>
                </div>
            </vs-prompt>
            <vs-prompt id="decrypted-email-prompt" vs-title="View Decrypted Email" @vs-cancel="closeDecryptedEmailPrompt" @vs-close="closeDecryptedEmailPrompt" :vs-buttons-hidden="true" :vs-active.sync="decryptedEmail['prompt']">
                <vs-alert active="true" class="h-auto" :class="{ 'mb-4': !!(decryptedEmail['value']) }">
                    <div class="break-all">
                        <template v-if="decryptedEmail['value']"><a :href="`/search?registrant_email=${encodeURIComponent(decryptedEmail['source'])}`" target="_blank">{{ decryptedEmail['value'] }}</a></template>
                        <template v-else>Value is being decrypted, please wait...</template>
                    </div>
                </vs-alert>
                <div v-if="decryptedEmail['value']" class="vx-row">
                    <div class="vx-col w-1/2">
                        <vs-button v-if="!(!!(decryptedEmail['whitelist']))" color="dark" type="border" class="w-full" @click="whitelistDecryptedEmailConfirm()">Whitelist</vs-button>
                        <vs-button v-else color="dark" type="border" class="w-full" @click="whitelistDecryptedEmailConfirm()">De-Whitelist</vs-button>
                    </div>
                    <div class="vx-col w-1/2">
                        <vs-button v-if="!(!!(decryptedEmail['blacklist']))" color="dark" type="border" class="w-full" @click="blacklistDecryptedEmailConfirm()">Blacklist</vs-button>
                        <vs-button v-else color="dark" type="border" class="w-full" @click="blacklistDecryptedEmailConfirm()">De-Blacklist</vs-button>
                    </div>
                </div>
            </vs-prompt>
		</template>
		
		<!-- Abuse Log -->
		<vx-card v-show="searchFlags['searched'] && !searchFlags['noResults']" title="Abuse Log" class="mb-base">
			<div slot="no-body" class="p-6 pt-0">
				<ag-grid-vue
					:gridOptions="gridOptions"
					class="ag-theme-material w-100 mb-4 ag-grid-table ag-grid-table--small"
					:columnDefs="columnDefs"
					:defaultColDef="defaultColDef"
					:rowData="domainLogs['abuseLog']"
					rowSelection="multiple"
					:enableRangeSelection="true"
					:suppressCopyRowsToClipboard="true"
					colResizeDefault="shift"
					:suppressDragLeaveHidesColumns="true"
					:animateRows="false"
					:floatingFilter="true"
					:pagination="true"
					:paginationPageSize="paginationPageSize"
					:suppressPaginationPanel="true"
					:suppressRowClickSelection="true"
					:rowClassRules="rowClassRules"
					:statusBar="statusBar"
					:sideBar="sideBar"
					:rowHeight="38"
					:getContextMenuItems="getContextMenuItems"
					@displayed-columns-changed="displayedColumnsChanged"
					@row-selected="onRowSelected"
					@column-resized="onColumnResized">
				</ag-grid-vue>
				<div class="vx-row">
					<div class="vx-col w-full">
						<vs-pagination
						:total="totalPages"
						:max="maxPageNumbers"
						v-model="currentPage" />
					</div>
				</div>
			</div>
		</vx-card>

		<template v-if="searchFlags['searched'] && !searchFlags['noResults']">
			<!-- Unsuspensions -->
			<unsuspensions :user-role="userRole" :table-data="domainLogs['unsuspensionLog']" />
			<!-- Registry Lock -->
			<registry-lock :user-role="userRole" :table-data="domainLogs['registryLockLog']" />
		</template>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";
	import Unsuspensions from '@/components/ag-grid-tables/Unsuspensions.vue'
	import RegistryLock from '@/components/ag-grid-tables/RegistryLock.vue'

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
			Unsuspensions,
			RegistryLock,
		},
		props: {
			paramId: {
				type: String,
				default: null
			},
			paramName: {
				type: String,
				default: null
			},
			userRole: {
				type: String,
				default: null
			},
		},
		data() {
			return {
				domainSearch: {
					name: '',
					id: '',
				},
				domainDetails: {
					name: '',
					tld: '',
					lifecycle: [],
				},
				searchFlags: {
					searched: false,
					noResults: false,
				},
				isGridReady: false,
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				modifiedColumnState: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?domain_name=${data.value}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
				},
				{
					headerName: 'Domain ID',
					field: 'domain_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['site','website','store','online','tech','press','host','fun','space','uno','pw','in.net'],
						suppressMiniFilter: true,
					},
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Source ID',
					field: 'source_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Source',
					field: 'source',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Category ID',
					field: 'category_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Category',
					field: 'category',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Transaction Date',
					field: 'transaction_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Detection Date',
					field: 'detection_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'User Email',
					field: 'user_email',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Action Date',
					field: 'action_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Registrar ID',
					field: 'registrar_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Registrar',
					field: 'registrar_organization',
					filter: "agTextColumnFilter",
					minWidth: 275,
					hide: true,
				},
				{
					headerName: 'Status',
					field: 'status',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['A','SH','SHP','SHF','PD','D'],
						suppressMiniFilter: true,
					},
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'A':
									return `<div class="badge badge--success inline">${data.value}</div>`
								case 'SH':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'SHP':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'SHF':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'PD':
									return `<div class="badge badge--info inline">${data.value}</div>`
								case 'D':
									return `<div class="badge badge--dark inline">${data.value}</div>`
								default:
									return `<div>-</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 100,
				},
				{
					headerName: 'Talos',
					field: 'suspicion_level',
					filter: "agTextColumnFilter",
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'high':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'medium':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'low':
									return `<div class="badge badge--primary inline">${data.value}</div>`
								default:
									return `<div>${data.value}</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Whitelisted',
					field: 'whitelisted',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Locked',
					field: 'locked',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Reserved',
					field: 'reserved',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Reviewed',
					field: 'reviewed',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Manual',
					field: 'manual',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Nameservers',
					field: 'nameservers',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant ID',
					field: 'registrant_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant Email',
					field: 'registrant_email',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?registrant_email=${encodeURIComponent(data.value)}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Registrant Phone',
					field: 'registrant_phone_voice',
					filter: "agTextColumnFilter",
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?registrant_phone=${encodeURIComponent(data.value)}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant State',
					field: 'registrant_state',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant Country',
					field: 'registrant_country',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Source All ID',
					field: 'source_all_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Row ID',
					field: 'id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					hide: true,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				selectedDomains: [],
				domainLogs: {
					abuseLog: [],
					unsuspensionLog: [],
					registryLockLog: [],
				},
				whitelistNode: {
					domain: null,
					flag: 0,
				},

				// Email Decryption
				password: {
					input: '',
					value: 'ea50da49e0be',
					prompt: false
				},
				decryptedEmail: {
                    source: '',
                    value: '',
                    whitelist: 0,
                    blacklist: 0,
					prompt: false
				},
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			modifiedColumnState: {
				handler: function(val) {
					localStorage.setItem('col-domain-details-'+this.$route.name, JSON.stringify({'state': val}));
				},
				deep: true
			},
			'password.input'() {
                if (this.password['input'] == this.password['value']) {
                    this.acceptPasswordPrompt();
                }
            }
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#domain-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#domain-loader > .con-vs-loading");
			},
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading-domain-search',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading-domain-search > .con-vs-loading");
			},
			domainLifecycleNumbers(index) {
				let length = this.domainDetails['lifecycle'].length;

				return length > 1 ? `${length - index}. `: '';
			},
			formatYears(years) {
				return years == 1 ? `${years} year` : `${years} years`;
			},
			formatStatus(status) {
				switch (status) {
					case 'SH':
						return 'Server Hold';
					case 'D':
						return 'Deleted';
					case 'PD':
						return 'Pending Delete';
					case 'SHF':
						return 'Server Hold Failed';
					case 'SHP':
						return 'Server Hold In Progress';
					default:
						return 'Active';
				}
			},
			formatFlag(flag) {
				if(flag) {
					return 'Yes'
				}
				else {
					return 'No'
				}
			},
			showActionButton(index, state, type) {
				if(index == 0) {
					if(type == 'status') {
						if (state == 'A') {
							return 'takedown';
						}
						else if (state == 'SH') {
							return 'unsuspend';
						}
						else {
							return false;
						}
					}
					else if (type == 'locked') {
						if (state == '0') {
							return 'lock';
						}
						else if (state == '1') {
							return 'unlock';
						}
						else {
							return false;
						}
					}
					else if (type == 'whitelisted') {
						if (state == '0') {
							return 'whitelist';
						}
						else if (state == '1') {
							return 'de-whitelist';
						}
						else {
							return false;
						}
					}
					else {
						return false;
					}
				}
				else {
					return false;
				}
			},
			displayedColumnsChanged() {
				if (this.isGridReady) {
					this.modifiedColumnState = this.gridOptions.columnApi.getColumnState();
				}
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			resetDetails(flag) {
				if(flag != 'searched') {
					this.domainSearch = {
						name: '',
						id: '',
					};
					this.searchFlags = {
						searched: false,
						noResults: false,
					};
					this.$router.replace('/search');
				}
				this.domainDetails = {
					name: '',
					tld: '',
					lifecycle: [],
				};
				this.domainLogs['abuseLog'] = [];
			},
			populateDomainSearch(type, value) {
				if(type == 'id') {
					this.domainSearch['id'] = value;
					// Search for domain
					this.search();
				}
				else if(type == 'name') {
					this.domainSearch['name'] = value;
					// Search for domain
					this.search();
				}
			},
			search() {
				let self = this,
					name = this.domainSearch['name'],
					id = this.domainSearch['id'];

				if(id != '') {
					// Show loader
					this.showButtonLoading();
					// Search by Domain ID
					this.$axiosSecure.get('/domain-search-id', {
						params: {
							id: id
						}
					})
					.then((response) => {
						// Set Searched Flag
						this.searchFlags['searched'] = true;
						// Assign Data
						if(!response.data.noResults) {
							this.domainDetails = response.data.details;
							this.domainLogs['abuseLog'] = response.data.abuse;
							this.domainLogs['unsuspensionLog'] = response.data.unsuspension;
							this.domainLogs['registryLockLog'] = response.data.registryLock;
							// Reset noResults Flag
							this.searchFlags['noResults'] = false;
							// Set isGridReady Flag
							this.isGridReady = true;
							// Set Column State
							let columnState = JSON.parse(localStorage.getItem('col-domain-details-'+self.$route.name));
							if(columnState) {
								this.gridOptions.columnApi.setColumnState(columnState.state);
							}
						}
						else {
							this.resetDetails('searched');
							this.searchFlags['noResults'] = true;
						}
						// Hide loader
						this.hideButtonLoading();
					})
					.catch((error) => {
						// Hide loader
						this.hideButtonLoading();
						// Confimation notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
					});
				}
				else if (name != '') {
					// Show loader
					this.showButtonLoading();
					// Search by Domain Name
					this.$axiosSecure.get('/domain-search-name', {
						params: {
							name: name
						}
					})
					.then((response) => {
						// Set Searched Flag
						this.searchFlags['searched'] = true;
						// Assign Data
						if(!response.data.noResults) {
							this.domainDetails = response.data.details;
							this.domainLogs['abuseLog'] = response.data.abuse;
							this.domainLogs['unsuspensionLog'] = response.data.unsuspension;
							this.domainLogs['registryLockLog'] = response.data.registryLock;
							// Reset noResults Flag
							this.searchFlags['noResults'] = false;
							// Set isGridReady Flag
							this.isGridReady = true;
							// Set Column State
							let columnState = JSON.parse(localStorage.getItem('col-domain-details-'+self.$route.name));
							if(columnState) {
								this.gridOptions.columnApi.setColumnState(columnState.state);
							}
						}
						else {
							this.resetDetails('searched');
							this.searchFlags['noResults'] = true;
						}
						// Hide loader
						this.hideButtonLoading();
					})
					.catch((error) => {
						// Hide loader
						this.hideButtonLoading();
						// Error notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
					});
				}
			},
			onRowSelected(event) {
				if(event.node.selected) {
					// Push to selectedDomains
					this.pushToArray(this.selectedDomains, event.node.data, 'domain_id');
				}
				else {
					// Remove from selectedDomains
					this.removeFromArray(this.selectedDomains, event.node.data, 'domain_id');
				}
			},
			takedown(id) {
				// Save takedown domains
				this.$store.dispatch("setTakedownDomains", [{
					domain: this.domainDetails['name'],
					domain_id: id,
					tld: this.domainDetails['tld']
				}]);
				// Redirect to listing
				this.$router.push({ path: '/suspension' })
			},
			takedownMultiple() {
				// Save takedown domains
				this.$store.dispatch("setTakedownDomains", this.selectedDomains.map(x => {
					return {
						domain: x['domain'],
						domain_id: x['domain_id'],
						tld: x['tld']
					};
				}));
				// Redirect to listing
				this.$router.push({ path: '/suspension' })
			},
			unsuspend(id) {
				// Save takedown domains
				this.$store.dispatch("setUnsuspensionDomains", [{
					domain: this.domainDetails['name'],
					domain_id: id,
					tld: this.domainDetails['tld']
				}]);
				// Redirect to listing
				this.$router.push({ path: '/unsuspension' })
			},
			unsuspendMultiple() {
				// Save takedown domains
				this.$store.dispatch("setUnsuspensionDomains", this.selectedDomains.map(x => {
					return {
						domain: x['domain'],
						domain_id: x['domain_id'],
						tld: x['tld']
					};
				}));
				// Redirect to listing
				this.$router.push({ path: '/unsuspension' })
			},
			registryLock(id, type) {
				// Save lock domains
				this.$store.dispatch("setRegistryLockDomains", [{
					domain: this.domainDetails['name'],
					domain_id: id,
					tld: this.domainDetails['tld']
				}]);
				if (type == 'lock') {
					// Redirect to listing
					this.$router.push({ path: '/registry-lock/lock' })	
				}
				else if (type == 'unlock') {
					// Redirect to listing
					this.$router.push({ path: '/registry-lock/unlock' })
				}
			},
			whitelistConfirm(obj, flag) {
				// Store whitelist obj
				this.whitelistNode['domain'] = obj;
				this.whitelistNode['flag'] = flag;

				// Assign msg
				if(flag) {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Remove the selected domain from whitelist?',
						accept: this.whitelistDomain
					});
				}
				else {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Whitelist the selected domain?',
						accept: this.whitelistDomain
					});
				}
			},
			whitelistDomain() {
				let self = this,
					postURL;

				// Show loader
				this.showDivLoading();

				// Assign Post URL
				if(this.whitelistNode['flag']) {
					postURL = '/delete-whitelisted-domain';
				}
				else {
					postURL = '/whitelist-domain';
				}
				
				// Whitelist Axios Call
				this.$axiosSecure.post(postURL, {
					...this.whitelistNode['domain'],
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					if(this.whitelistNode['flag']) {
						// Confimation notification
						self.$vs.notify({
							color: 'success',
							title: 'Whitelist success',
							text: 'The selected domain has been removed from whitelist'
						});
					}
					else {
						// Confimation notification
						self.$vs.notify({
							color: 'success',
							title: 'Whitelist success',
							text: 'The selected domain has been whitelisted'
						});
					}
						
					// Hide loader
					this.hideDivLoading();
					// Refresh domain details
					this.search();
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "View Domain Details",
							action: function() {
								let routeData = self.$router.resolve({name: 'search', query: {domain_name: params.value}});
								window.open(routeData.href, '_blank');
							},
						},
						'separator',
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			},

			// Email Decryption
			viewDecryptedValue(email) {
				this.decryptedEmail['source'] = email;

                // Check PIN cache
                if (this.getLocalStorageWithExpiry('encryptedEmail')) {
                    // Open Email Prompt
                    this.openDecryptedEmailPrompt();
                }
                else {
                    // Open Password Prompt
                    this.password['prompt'] = true;
                }
            },
			closePasswordPrompt() {
                this.password['input'] = '';
                this.password['prompt'] = false;
            },
			acceptPasswordPrompt() {
                // Close Password Prompt
                this.closePasswordPrompt();

                // Set cache for PIN
                this.setLocalStorageWithExpiry('encryptedEmail', this.decryptedEmail['source'], 1800000);

                // Open Email Prompt
                this.openDecryptedEmailPrompt();
            },
			openDecryptedEmailPrompt() {
                this.decryptedEmail['value'] = '';
                this.decryptedEmail['prompt'] = true;
                
                // API call to get value
                this.$axiosSecure.get('/decrypt', {
                    params: {
                        email: this.decryptedEmail['source']
                    }
                })
                .then((response) => {
                    this.decryptedEmail['value'] = response.data.decrypted_email != undefined ? response.data.decrypted_email : this.decryptedEmail['source'];
                    this.decryptedEmail['whitelist'] = Number(response.data.whitelisted_email);
                    this.decryptedEmail['blacklist'] = Number(response.data.blacklisted_email);
                })
                .catch((error) => {
                    // Error notification
                    this.$vs.notify({
                        color: 'danger',
                        title: 'Something went wrong',
                        text: 'Please contact the server admin'
                    });

                    console.log(error);
                });
            },
			closeDecryptedEmailPrompt() {
                // Reset Decrypted Prompt
                this.decryptedEmail['prompt'] = false;
            },
			whitelistDecryptedEmailConfirm() {
                // Close Decrypted Prompt
                this.closeDecryptedEmailPrompt();

                // Check whitelist status
				if(this.decryptedEmail['whitelist']) {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Remove the selected email from whitelist?',
						accept: this.whitelistDecryptedEmail
					});
				}
				else {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Whitelist the selected email?',
						accept: this.whitelistDecryptedEmail
					});
				}
            },
            whitelistDecryptedEmail() {
                // Check whitelist status
				if(this.decryptedEmail['whitelist']) {
                    // De-Whitelist Axios Call
                    this.$axiosSecure.post('/de-whitelist-email', {
                        email: this.decryptedEmail['source'],
						watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
                    })
                    .then(() => {
                        // Confimation notification
                        this.$vs.notify({
                            color: 'success',
                            title: 'Whitelist success',
                            text: 'The selected email has been removed from whitelist'
                        });
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
                else {
                    // Whitelist Axios Call
                    this.$axiosSecure.post('/whitelist-email', {
                        email: this.decryptedEmail['source'],
						watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
                    })
                    .then((success) => {
                        if(success.data == "created") {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'success',
                                title: 'Whitelist success',
                                text: 'The selected email has been whitelisted'
                            });
                        }
                        else {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'warning',
                                title: 'Whitelist error',
                                text: 'The selected email is already whitelisted'
                            });
                        }
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
            },
			blacklistDecryptedEmailConfirm() {
                // Close Decrypted Prompt
                this.closeDecryptedEmailPrompt();

                // Check blacklist status
				if(this.decryptedEmail['blacklist']) {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Remove the selected email from blacklist?',
						accept: this.blacklistDecryptedEmail
					});
				}
				else {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Blacklist the selected email?',
						accept: this.blacklistDecryptedEmail
					});
				}
            },
            blacklistDecryptedEmail() {
                // Check blacklist status
				if(this.decryptedEmail['blacklist']) {
                    // De-Blacklist Axios Call
                    this.$axiosSecure.post('/remove-blacklisted-registrant-email', {
                        email: this.decryptedEmail['source'],
						watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
                    })
                    .then(() => {
                        // Confimation notification
                        this.$vs.notify({
                            color: 'success',
                            title: 'Blacklist success',
                            text: 'The selected email has been removed from blacklist'
                        });
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
                else {
                    // Blacklist Axios Call
                    this.$axiosSecure.post('/save-blacklisted-registrant-email', {
                        email: this.decryptedEmail['source'],
						watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
                    })
                    .then((success) => {
                        if(success.data == "created") {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'success',
                                title: 'Blacklist success',
                                text: 'The selected email has been blacklisted'
                            });
                        }
                        else {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'warning',
                                title: 'Blacklist error',
                                text: 'The selected email is already blacklisted'
                            });
                        }
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
            }
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Prepopulate Domain Name
			if(this.paramId) {
				this.populateDomainSearch('id', this.paramId);
			}
			else if(this.paramName) {
				this.populateDomainSearch('name', this.paramName);
			}
		}
	}
</script>
