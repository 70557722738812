<template>
	<div>
		<vx-card class="overflow-hidden mb-base">
			<div slot="no-body">
				<div class="vs-tabs--custom con-vs-tabs vs-tabs vs-tabs-primary vs-tabs-position-top">
					<div class="con-ul-tabs">
						<ul class="ul-tabs vs-tabs--ul ul-tabs-fixed">
							<li class="vs-tabs--li" :class="{'activeChild' : searchView == 'domain'}">
								<button type="button" class="vs-tabs--btn" @click="toggleSearchView('domain')">Domain<span class="hidden md:inline"> Search</span></button>
							</li>
							<li class="vs-tabs--li" :class="{'activeChild' : searchView == 'bulk-domain'}">
								<button type="button" class="vs-tabs--btn" @click="toggleSearchView('bulk-domain')">Bulk Domain<span class="hidden md:inline"> Search</span></button>
							</li>
							<li class="vs-tabs--li" :class="{'activeChild' : searchView == 'registrant'}">
								<button type="button" class="vs-tabs--btn" @click="toggleSearchView('registrant')">Registrant<span class="hidden md:inline"> Search</span></button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</vx-card>

		<domain-details :user-role="user_role" v-show="searchView == 'domain'" :param-id="getUrlParameter('domain_id')" :param-name="getUrlParameter('domain_name')"/>
		<bulk-domain-details :user-role="user_role" v-show="searchView == 'bulk-domain'"/>
		<registrant-details :user-role="user_role" v-show="searchView == 'registrant'" :param-email="getUrlParameter('registrant_email') ? decodeURIComponent(getUrlParameter('registrant_email')) : null" :param-phone="getUrlParameter('registrant_phone') ? decodeURIComponent(getUrlParameter('registrant_phone')) : null"/>
	</div>
</template>

<script>
	import DomainDetails from '@/components/search/DomainDetails.vue'
	import BulkDomainDetails from '@/components/search/BulkDomainDetails.vue'
	import RegistrantDetails from '@/components/search/RegistrantDetails.vue'

	export default {
		components: {
			DomainDetails,
			BulkDomainDetails,
			RegistrantDetails
		},
		data() {
			return {
				searchView: 'domain',
			}
		},
		watch: {
			
		},
		computed: {
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			toggleSearchView(type) {
				this.searchView = type;
			},
			getUrlParameter(name) {
				return this.$route.query[name];
			},
		},
		mounted() {
			if(this.getUrlParameter('domain_id') || this.getUrlParameter('domain_name')) {
				this.searchView = 'domain';
			}
			else if(this.getUrlParameter('registrant_email') || this.getUrlParameter('registrant_phone')) {
				this.searchView = 'registrant';
			}
		}
	}
</script>
