<template>
	<div>
		<vx-card class="mb-base">
			<div class="vx-row">
				<div class="vx-col w-full mb-2">
					<label class="vs-input--label">Domains</label>
					<vs-textarea class="w-full" v-model="domainSearch['names']" />
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButtonBulkDomainSearch" id="button-with-loading-bulk-domain-search"  class="mb-2 mr-4" @click="search()">Search</vs-button>
					<vs-button class="mb-2" color="danger" type="border" @click="resetDetails()">Reset</vs-button>
				</div>
			</div>
		</vx-card>

		<!-- No Results Alert -->
		<div v-if="searchFlags['searched'] && searchFlags['noResults']" class="vx-col w-full mb-base">
			<vs-alert active="true" icon="new_releases">No domains found for the above search criteria, please change the search criteria & try again.</vs-alert>
		</div>

		<!-- Domain Details -->
		<template v-if="searchFlags['searched'] && !searchFlags['noResults']">
			<!-- New Registrations -->
			<new-registrations :user-role="userRole" :table-data="domainDetails['newRegistrations']" />
			<!-- Abuse Reported -->
			<abuse-reported :user-role="userRole" :table-data="domainDetails['abuseReported']" />
			<!-- Unsuspensions -->
			<unsuspensions :user-role="userRole" :table-data="domainLogs['unsuspensionLog']" />
			<!-- Registry Lock -->
			<registry-lock :user-role="userRole" :table-data="domainLogs['registryLockLog']" />
		</template>
	</div>
</template>

<script>
	import NewRegistrations from '@/components/ag-grid-tables/NewRegistrations.vue'
	import AbuseReported from '@/components/ag-grid-tables/AbuseReported.vue'
	import Unsuspensions from '@/components/ag-grid-tables/Unsuspensions.vue'
	import RegistryLock from '@/components/ag-grid-tables/RegistryLock.vue'

	export default {
		components: {
			AbuseReported,
			NewRegistrations,
			Unsuspensions,
			RegistryLock,
		},
		props: {
			userRole: {
				type: String,
				default: null
			},
		},
		data() {
			return {
				domainSearch: {
					names: '',
				},
				domainDetails: {
					newRegistrations: [],
					abuseReported: [],
				},
				domainLogs: {
					unsuspensionLog: [],
					registryLockLog: [],
				},
				searchFlags: {
					searched: false,
					noResults: false,
				},
			}
		},
		watch: {
			
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading-bulk-domain-search',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading-bulk-domain-search > .con-vs-loading");
			},
			resetDetails(flag) {
				if(flag != 'searched') {
					this.domainSearch = {
						names: ''
					};
					this.searchFlags = {
						searched: false,
						noResults: false,
					};
					this.$router.replace('/search');
				}
				this.domainDetails = {
					newRegistrations: [],
					abuseReported: [],
				};
			},
			search() {
				let self = this,
					names = this.domainSearch['names'];

				// Show loader
				this.showButtonLoading();
				// Search by Domain Name
				this.$axiosSecure.post('/bulk-domain-search', {
					names: names
				})
				.then((response) => {
					// Set Searched Flag
					this.searchFlags['searched'] = true;
					// Assign Data
					if(!response.data.noResults) {
						this.domainDetails = response.data;
						this.domainLogs['unsuspensionLog'] = response.data.unsuspension;
						this.domainLogs['registryLockLog'] = response.data.registryLock;
						// Reset noResults Flag
						this.searchFlags['noResults'] = false;
					}
					else {
						this.resetDetails('searched');
						this.searchFlags['noResults'] = true;
					}
					// Hide loader
					this.hideButtonLoading();
				})
				.catch((error) => {
					// Hide loader
					this.hideButtonLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
		},
		beforeMount() {
			
		},
		mounted() {
			
		}
	}
</script>
